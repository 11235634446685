<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <div class="card">
          <div class="card-header pb-xs px-1">
            <h5>Plan Types</h5>
            <div class="heading-elements">
            <ul class="list-inline mb-0">
                <li>
                  <dx-util-button
                    type="default"
                    styling-mode="contained"
                    icon="add"
                    text="Add New"
                    class="mr-xs"
                    @click="repeatAgain"
                  />
                </li>
                <li>
                  <dx-util-button
                    type="danger"
                    styling-mode="contained"
                    icon="chevronprev"
                    text="Back to Plans"
                    @click="onClickBackPlan"
                  />
                </li>
            </ul>
            </div>
          </div>
          <div class="card-body p-0">
            <div v-for="(item, index) in items" :id="item.id" :key="item.id" class="d-flex flex-row justify-content-start px-1 pb-xs mt-xs" :class="((items.length -1) !== index) ? 'border-bottom': ''">
              <div class="mr-1 w-25">
                <h6 v-if="index === 0" class="mt-xs">
                  Name
                </h6>
                <dx-util-text-box
                  v-model="item.typeName"
                  styling-mode="outlined"
                />
              </div>
              <div class="w-100 mr-1">
                <h6 v-if="index === 0" class="mt-xs">
                  Notes
                </h6>
                <dx-util-text-box
                  v-model="item.notes"
                  styling-mode="outlined"
                />
              </div>
              <div>
                <h6 v-if="index === 0" class="mt-xs">
                  Actions
                </h6>
                <div class="d-flex">
                  <dx-util-button
                    type="success" icon="save"
                    class="mr-half" hint="Edit"
                    @click="saveItem(index)"
                  />
                  <dx-util-button
                    type="danger" icon="bi bi-trash"
                    hint="Delete"
                    @click="deleteItem(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import planManagementService from '@/http/requests/plan/planManagementService'

export default {
  data() {
    return {
      initialSize: 0,
      itemFields: {
        id: '',
        typeName: '',
        notes: '',
      },
      items: [],
    }
  },
  mounted() {
  },
  created() {
    planManagementService.fetchAllPlanTypes().then(result => {
      this.items.splice(0, this.items.length)
      result.data.forEach(element => {
        this.items.push(element)
      })
    })
  },
  methods: {
    itemClick(e, index) {
      if (e.itemData.event === 'save') {
        this.saveItem(index)
      }
      if (e.itemData.event === 'delete') {
        this.deleteItem(index)
      }
    },
    saveItem(index) {
      const item = this.items[index]
      if (index < this.initialSize) {
        planManagementService.updatePlanType(item).then(() => {
          this.items[index].typeName = item.typeName
          this.items[index].notes = item.notes
        })
      } else {
        planManagementService.createPlanType(item).then(result => {
          const data = result.data
          this.items[this.items.length - 1].id = data.id
        })
      }
    },
    repeatAgain() {
      if (this.items.length > 0 && this.items[this.items.length - 1].id === '') {
        return
      }
      this.items.push(this.itemFields)
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    deleteItem(index) {
      this.$swal({
        title: 'Are you sure you want to delete this PlanType record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const item = this.items[index]
          planManagementService.deletePlanTypeById(item.id).then(() => {
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
            this.$swal({
              icon: 'error',
              title: 'Deleted!',
              text: 'PlanType record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    onClickBackPlan() {
      this.$router.push({ name: 'route-plans-plan-management' })
    },
  },
}
</script>

<style lang="scss" scoped>
.mt-xs {
  margin-top: 0.357rem; //5 px
}
.mr-xs {
  margin-right: 0.357rem; //5 px
}
.pb-xs {
  padding-bottom: 0.357rem; //5 px
}
</style>
